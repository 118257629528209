// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app\\contexts\\partner-context.tsx"
);
import.meta.hot.lastModified = "1734032458103.9067";
}
// REMIX HMR END

// app/contexts/partner-context.tsx
import { createContext, useContext } from 'react';
import type { PartnerConfig } from '~/types/partner';

interface PartnerContextType {
  partnerConfig: PartnerConfig | null;
}

const PartnerContext = createContext<PartnerContextType | undefined>(undefined);

export function usePartner() {
  const context = useContext(PartnerContext);
  if (context === undefined) {
    throw new Error('usePartner must be used within a PartnerProvider');
  }
  return context;
}

export const PartnerProvider = PartnerContext.Provider;