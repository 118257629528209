// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\components\\\\theme-provider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\components\\theme-provider.tsx");
  import.meta.hot.lastModified = "1734041773669.778";
}
// REMIX HMR END

// app/components/theme-provider.tsx
import { useEffect, useState } from 'react';
import { usePartner } from '~/contexts/partner-context';
import { getStoredPartnerConfig } from '~/utils/partner-storage';
export function ThemeProvider({
  children
}) {
  _s();
  const {
    partnerConfig
  } = usePartner();
  const [isThemeReady, setIsThemeReady] = useState(false);
  useEffect(() => {
    const root = document.documentElement;
    // Try to get config from context or storage
    const config = partnerConfig || getStoredPartnerConfig();

    // Create a promise to handle theme application
    const applyTheme = async () => {
      if (config?.theme) {
        console.log('[ThemeProvider] Applying theme:', config.theme);

        // Set brand variables
        root.style.setProperty('--brand-primary', config.theme.primaryColor);
        root.style.setProperty('--brand-secondary', config.theme.secondaryColor);
        root.style.setProperty('--brand-header', config.theme.headerColor);

        // Set contrast text colors for better readability
        const getContrastColor = hexColor => {
          const color = hexColor.replace('#', '');
          const r = parseInt(color.substr(0, 2), 16);
          const g = parseInt(color.substr(2, 2), 16);
          const b = parseInt(color.substr(4, 2), 16);
          const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
          return luminance > 0.5 ? '#000000' : '#ffffff';
        };
        root.style.setProperty('--brand-text-primary', getContrastColor(config.theme.primaryColor));
        root.style.setProperty('--brand-text-secondary', getContrastColor(config.theme.secondaryColor));
        root.style.setProperty('--brand-text-header', getContrastColor(config.theme.headerColor));
        root.classList.add('partner-theme');
      } else {
        console.log('[ThemeProvider] No theme found, using Dooiu defaults');
        root.classList.remove('partner-theme');
        root.style.removeProperty('--brand-primary');
        root.style.removeProperty('--brand-secondary');
        root.style.removeProperty('--brand-header');
        root.style.removeProperty('--brand-text-primary');
        root.style.removeProperty('--brand-text-secondary');
        root.style.removeProperty('--brand-text-header');
      }

      // Add a small delay to ensure styles are properly applied
      await new Promise(resolve => setTimeout(resolve, 50));
      setIsThemeReady(true);
    };

    // Apply theme and handle loading state
    applyTheme();

    // Cleanup function
    return () => {
      setIsThemeReady(false);
    };
  }, [partnerConfig]);

  // Show nothing while theme is being applied
  if (!isThemeReady) {
    return <div className="fixed inset-0 bg-white dark:bg-zinc-800">
        <div className="flex items-center justify-center h-full">
          <div className="animate-pulse"></div>
        </div>
      </div>;
  }
  return <>{children}</>;
}
_s(ThemeProvider, "0qjuji6ZyWPsrbi329pp8hYh4UQ=", false, function () {
  return [usePartner];
});
_c = ThemeProvider;
var _c;
$RefreshReg$(_c, "ThemeProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;