// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app\\i18n\\i18n.client.ts"
);
import.meta.hot.lastModified = "1733943850905.2004";
}
// REMIX HMR END

// app/i18n/i18n.client.ts
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

console.log('🚀 Starting i18n configuration...');

const i18n = i18next;  // Use the default instance instead of creating new one

i18n.on('initialized', (options) => {
  console.log('🌍 i18next initialized:', {
    language: i18n.language,
    languages: i18n.languages,
  });
});

i18n.on('loaded', (loaded) => {
  console.log('📚 Translations loaded:', loaded);
});

i18n.on('failedLoading', (lng, ns, msg) => {
  console.error('❌ Failed loading translation:', { lng, ns, msg });
});

i18n.on('missingKey', (lngs, namespace, key, res) => {
  console.warn('⚠️ Missing translation key:', { lngs, namespace, key, res });
});

// Configure and initialize immediately
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
      debug: true,
    },
    resources: {
      en: {
        common: {} // Will be populated during initialization
      },
      es: {
        common: {} // Will be populated during initialization
      }
    }
  });

// Function to update resources
export async function updateI18nResources(locale: string) {
  try {
    const response = await fetch(`/locales/${locale}/common.json`);
    const translations = await response.json();
    
    i18n.addResources(locale, 'common', translations);
    await i18n.changeLanguage(locale);
    return true; // Indicate success
  } catch (error) {
    console.error('Failed to load translations:', error);
    throw error; // Propagate error for proper handling
  }
}

export default i18n;