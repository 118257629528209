// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\root.tsx");
}
// REMIX HMR END

import { json, redirect } from '@remix-run/cloudflare';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { parse } from 'cookie';
import { useRef, useEffect, useState } from 'react';
import { useFullscreen, useToggle } from 'react-use';
import { QueryClient, QueryClientProvider } from 'react-query';
import tailwind from '~/styles/tailwind.css';
import { elementNotContainedByClickTarget } from './utils/elementNotContainedByClickTarget';
import getUsername from './utils/getUsername.server';
import { cn } from './utils/style';
import { updateI18nResources } from './i18n/i18n.client';
import { getPartnerConfig } from '~/utils/partner-config.server';
import { PartnerProvider } from '~/contexts/partner-context';
import { ThemeProvider } from '~/components/theme-provider';
import { setStoredPartnerConfig } from '~/utils/partner-storage';
import { getStoredPartnerConfig } from '~/utils/partner-storage';
import { createRedirectUrlWithPartner } from '~/utils/getUsername.server';
function addOneDay(date) {
  const result = new Date(date);
  result.setTime(result.getTime() + 24 * 60 * 60 * 1000);
  return result;
}
export const loader = async ({
  request,
  context
}) => {
  const url = new URL(request.url);
  const username = await getUsername(request);
  const partnerId = url.searchParams.get('pId');
  const isRedirect = url.searchParams.get('return-url');
  let partnerConfig = null;
  if (partnerId) {
    console.log('[Root Loader] Fetching partner config with:', {
      partnerId,
      hasBinding: !!context.env.PARTNER_CONFIG_WORKER
    });
    partnerConfig = await getPartnerConfig(partnerId, context.env);
    console.log('[Root Loader] Received partner config:', partnerConfig);
  }
  const shouldClearStoredConfig = url.pathname === '/default';

  // Existing username check
  if (!username && url.pathname !== '/set-username') {
    const redirectUrl = new URL('/set-username', request.url);

    // Preserve the partner ID
    if (partnerId) {
      redirectUrl.searchParams.set('pId', partnerId);
    }

    // Store the return URL with partner ID preserved
    const returnUrl = createRedirectUrlWithPartner(request.url, request);
    redirectUrl.searchParams.set('return-url', returnUrl.toString());
    throw redirect(redirectUrl.toString());
  }

  // Get browser language
  const acceptLanguage = request.headers.get('Accept-Language') || '';
  const preferredLanguage = acceptLanguage.split(',')[0].split('-')[0];
  const locale = ['en', 'es'].includes(preferredLanguage) ? preferredLanguage : 'en';
  console.log('Accept-Language header:', acceptLanguage);
  console.log('Detected preferred language:', preferredLanguage);
  console.log('Final locale:', locale);
  const defaultResponse = json({
    userDirectoryUrl: context.env.USER_DIRECTORY_URL,
    locale,
    partnerConfig,
    shouldClearStoredConfig
  });

  // we only care about verifying token freshness if request was a user
  // initiated document request.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-Fetch-User
  const secFetchUser = request.headers.get('Sec-Fetch-User');
  if (secFetchUser !== '?1') return defaultResponse;
  const cookiesHeader = request.headers.get('Cookie');
  if (!cookiesHeader) return defaultResponse;
  const {
    CF_Authorization
  } = parse(cookiesHeader);
  if (!CF_Authorization) return defaultResponse;
  const [, payload] = CF_Authorization.split('.');
  const data = JSON.parse(atob(payload));
  const expires = new Date(data.exp * 1000);
  const now = new Date();
  if (addOneDay(now) > expires) {
    const headers = new Headers();
    ['CF_Authorization', 'CF_AppSession'].forEach(cookieName => headers.append('Set-Cookie', `${cookieName}=; Expires=${new Date(0).toUTCString()}; Path=/;`));
    throw redirect(request.url, {
      headers
    });
  }
  return defaultResponse;
};
export const meta = () => [{
  title: 'Dooiu Meets'
}];
export const links = () => [{
  rel: 'stylesheet',
  href: tailwind
}, {
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/apple-touch-icon.png?v=Dooiu'
}, {
  rel: 'icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/favicon-32x32.png?v=Dooiu'
}, {
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/favicon-16x16.png?v=Dooiu'
}, {
  rel: 'manifest',
  href: '/site.webmanifest',
  crossOrigin: 'use-credentials'
}, {
  rel: 'mask-icon',
  href: '/safari-pinned-tab.svg?v=Dooiu',
  color: '#ff3300'
}, {
  rel: 'shortcut icon',
  href: '/favicon.ico?v=dooiu'
}];
const Document = ({
  children
}) => {
  _s();
  const {
    locale,
    partnerConfig
  } = useLoaderData();
  const fullscreenRef = useRef(null);
  const [fullscreenEnabled, toggleFullscreen] = useToggle(false);
  useFullscreen(fullscreenRef, fullscreenEnabled, {
    onClose: () => toggleFullscreen(false)
  });
  const storedConfig = getStoredPartnerConfig();
  const activeConfig = partnerConfig || storedConfig;
  const initialStyles = `
        /* Hide content initially */
        .root-content {
            opacity: 0;
            transition: opacity 0.2s ease-in;
        }
        
        /* Show content when theme is ready */
        .theme-ready .root-content {
            opacity: 1;
        }
        
        ${activeConfig?.theme ? `
            :root {
                --brand-primary: ${activeConfig.theme.primaryColor};
                --brand-secondary: ${activeConfig.theme.secondaryColor};
                --brand-header: ${activeConfig.theme.headerColor};
                --brand-text-primary: #ffffff;
                --brand-text-secondary: #ffffff;
                --brand-text-header: #ffffff;
            }
        ` : ''}
    `;
  return <html className="h-full" lang={locale} suppressHydrationWarning>
			<head>
				<meta charSet="utf-8" />
				{initialStyles && <style dangerouslySetInnerHTML={{
        __html: initialStyles
      }} />}
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="apple-mobile-web-app-title" content="Dooiu Meets" />
				<meta name="application-name" content="Dooiu Meets" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta property="og:title" content="Dooiu Meets" />
  				<meta property="og:description" content="Do you meet?" />
 				 <meta property="og:type" content="website" />
 				 <meta property="og:url" content="https://meet.dooiu.com" />
  				<meta property="og:image" content="https://meet.dooiu.com/android-chrome-512x512.png" />
				<meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
        
				<meta name="theme-color" content="#232325" media="(prefers-color-scheme: dark)" />
        
				<Meta />
				<Links />
			</head>
			<body className={cn('h-full', 'bg-white', 'text-zinc-800', 'dark:bg-zinc-800', 'dark:text-zinc-200')} ref={fullscreenRef} onDoubleClick={e => {
      if (e.target instanceof HTMLElement && !elementNotContainedByClickTarget(e.target)) toggleFullscreen();
    }}>
        
				{children}
				<ScrollRestoration />
				<div className="hidden" style={{
        display: 'none'
      }} suppressHydrationWarning>
                    __CLIENT_ENV__
                </div>
				<Scripts />
				{process.env.NODE_ENV === 'development' && <LiveReload />}
			</body>
		</html>;
};
_s(Document, "tD+aCo0hVRJWlvVYHYNNa2BpGLA=", false, function () {
  return [useLoaderData, useToggle, useFullscreen];
});
_c = Document;
export const ErrorBoundary = () => {
  return <Document>
			<div className="grid h-full place-items-center">
				<p>
					It looks like there was an error, but don't worry it has been
					reported. Sorry about that!
				</p>
			</div>
		</Document>;
};
_c2 = ErrorBoundary;
const queryClient = new QueryClient();
export default function App() {
  _s2();
  const {
    userDirectoryUrl,
    locale,
    partnerConfig,
    shouldClearStoredConfig
  } = useLoaderData();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(!!partnerConfig);
  useEffect(() => {
    if (shouldClearStoredConfig) {
      console.log('[App] Clearing stored partner config - at exact root URL');
      setStoredPartnerConfig(null);
    } else if (partnerConfig) {
      console.log('[App] Storing new partner config');
      setStoredPartnerConfig(partnerConfig);
    }
    setStylesLoaded(true);
  }, [partnerConfig, shouldClearStoredConfig]);
  const timer = setTimeout(() => {
    setStylesLoaded(true);
  }, 50);
  useEffect(() => {
    // Update resources when locale changes
    updateI18nResources(locale).then(() => setTranslationsLoaded(true)).catch(console.error);
  }, [locale]);
  if (!translationsLoaded || !stylesLoaded) {
    return <Document>
                <div className="fixed inset-0 bg-white dark:bg-zinc-800">
                    <div className="flex items-center justify-center h-full">
                        <div className="animate-pulse">Loading...</div>
                    </div>
                </div>
            </Document>;
  }
  return <Document>
		  <div id="root" className="h-full bg-inherit isolate">
			<QueryClientProvider client={queryClient}>
			  <PartnerProvider value={{
          partnerConfig
        }}>
				<ThemeProvider>
				  <Outlet context={{
              userDirectoryUrl,
              locale,
              partnerConfig
            }} />
              
				</ThemeProvider>
			  </PartnerProvider>
			</QueryClientProvider>
		  </div>
		</Document>;
}
_s2(App, "vyRiHaNalrUnWKC3S8y5FoHCUIo=", false, function () {
  return [useLoaderData];
});
_c3 = App;
var _c, _c2, _c3;
$RefreshReg$(_c, "Document");
$RefreshReg$(_c2, "ErrorBoundary");
$RefreshReg$(_c3, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;